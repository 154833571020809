<template>
    <div class="ModelUploadCom">
        <div class="title">
            {{ title }}
            <el-affix :offset="100" style="float: left; cursor: pointer" v-if="isEdit">
                <span style="padding-left: 10px" @click="handleClose">
                    <el-button size="small">返回</el-button>
                </span>
            </el-affix>
        </div>
        <div class="upload-content-wrap">
            <el-row>
                <el-col :span="2">
                    <div>基本信息：</div>
                </el-col>
                <el-col :span="21">
                    <el-form ref="modelUploadForm" size="small" :model="modelUploadForm" :rules="rules"
                        style="padding-bottom: 50px" label-width="200px">
                        <el-form-item v-if="!isEdit" class="upload-content-tips">
                            <span>!</span>
                            <span>请选择您要上传的模型体系，并填写相关信息</span>
                        </el-form-item>
                        <el-form-item label="模型体系：" required>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="system">
                                        <el-select size="small" placeholder="请选择体系" clearable
                                            v-model="modelUploadForm.system" @change="changeClassificationId()">
                                            <el-option v-for="item in systemList" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 20px">
                                    <el-form-item prop="classification">
                                        <el-select size="small" placeholder="请选择分类" :popper-append-to-body="false"
                                            clearable v-model="modelUploadForm.classification"
                                            @change="changeMaterialId()">
                                            <el-option v-for="item in classificationList" :key="item.id"
                                                :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <!-- <el-col :span="6" style="padding-left: 20px" v-if="materialList.length > 0">
                                    <el-form-item prop="material">
                                        <el-select
                                            size="small"
                                            placeholder="请选择材料"
                                            :popper-append-to-body="false"
                                            clearable
                                            v-model="modelUploadForm.material"
                                        >
                                            <el-option
                                                v-for="item in materialList"
                                                :key="item.id"
                                                :label="item.name"
                                                :value="item.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="6" style="padding-left: 20px">
                                    <el-button size="small" type="primary" @click="jump(1)">类型管理</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="品牌：" required>
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="brandId">
                                        <el-select size="small" v-model="modelUploadForm.brandId" clearable
                                            @change="changeBrand(true)" placeholder="请选择品牌">
                                            <el-option v-for="(item, index) in brandList" :key="index"
                                                :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col v-if="brandSeries_list.length" :span="6" style="padding-left: 20px">
                                    <el-form-item prop="series_id">
                                        <el-select placeholder="请选择系列" :popper-append-to-body="false"
                                            style="width: 100%" v-model="modelUploadForm.series_id">
                                            <el-option v-for="item in brandSeries_list" :key="item.seriesId"
                                                :label="item.seriesName" :value="item.seriesId"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 20px">
                                    <el-button size="small" type="primary" @click="jump(2)">品牌管理</el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="类型：" prop="type">
                            <el-radio-group size="medium" v-model="modelUploadForm.type" @change="changeType">
                                <el-radio v-for="(item, index) in typeList" :label="item.id" :key="index">{{
                                item.name
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="模型名称：" prop="modelName">
                            <el-input size="small" style="width: 200px" maxlength="20"
                                v-model="modelUploadForm.modelName"></el-input>
                            <span style="margin-left: 10px; color: red">{{ errorMessage }}</span>
                        </el-form-item>
                        <el-form-item label="模型编号：" prop="modelNumber">
                            <el-input size="small" style="width: 200px" maxlength="20"
                                v-model="modelUploadForm.modelNumber"></el-input>
                            <span style="margin-left: 10px; color: red">{{ errorMessage1 }}</span>
                        </el-form-item>
                        <!--                        <el-form-item class="upload-file-wrap" label="上传文件：" prop="file">-->
                        <!--                            <div>-->
                        <!--                                <el-upload-->
                        <!--                                    v-loading="uploadFileLoaing"-->
                        <!--                                    class="upload-file"-->
                        <!--                                    drag-->
                        <!--                                    action="#"-->
                        <!--                                    ref="uploadFileRef"-->
                        <!--                                    :file-list="modelUploadForm.file"-->
                        <!--                                    :limit="1"-->
                        <!--                                    :auto-upload="false"-->
                        <!--                                    accept=".png,.jpg,.zip"-->
                        <!--                                    :on-change="handleUploadFile"-->
                        <!--                                    :on-remove="handleUploadFileRemove"-->
                        <!--                                    :on-exceed="handleExceed"-->
                        <!--                                >-->
                        <!--                                    &lt;!&ndash; <img v-if="modelUploadForm.file" :src="modelUploadForm.file" class="avatar" /> &ndash;&gt;-->
                        <!--                                    <el-icon class="el-icon-upload"></el-icon>-->
                        <!--                                    <div class="el-upload__text"><em>点击</em>或拖拽文件到此处上传</div>-->
                        <!--                                    <template #tip>-->
                        <!--                                        <div class="el-upload__tip"></div>-->
                        <!--                                    </template>-->
                        <!--                                </el-upload>-->
                        <!--                            </div>-->
                        <!--                            <div class="upload-tip-message">-->
                        <!--                                <div>-->
                        <!--                                    <span>模型格式为.fbx, 结构件模型顶点须小于800，三角面小于1500。</span>-->
                        <!--                                </div>-->
                        <!--                                <div>-->
                        <!--                                    <span>请将模型文件转为.zip格式的压缩文件后上传，单个压缩包大小不可超过5MB.</span>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </el-form-item>-->

                        <el-form-item v-if="modelUploadForm.type == 2" label="构件规格：" class="specsInput" required>
                            <span v-for="(item, index) in modelUploadForm.format.arr" :key="index">
                                <el-row style="margin-bottom: 8px">
                                    <el-col :span="1">
                                        <el-radio v-model="modelUploadForm.format.status" :label="index">&nbsp;
                                        </el-radio>
                                    </el-col>
                                    <el-col :span="6" style="padding-right: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.length'"
                                            :rules="rules.format[0]">
                                            <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput4" style="width: 100%"
                                                v-model="item.length"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" style="padding-left: 3px; padding-right: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.width'" :rules="rules.format[1]">
                                            <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                v-model="item.width"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="6" style="padding-left: 3px">
                                        <el-form-item :prop="'format.arr.' + index + '.height'"
                                            :rules="rules.format[2]">
                                            <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                suffix-icon="el-icon-search" class="specsInput3"
                                                @change="changeHeight(item.height, index)" style="width: 100%"
                                                v-model="item.height"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="5">
                                        <span v-if="index == 0" class="addIcon" @click="addSpace(1)">
                                            <i class="el-icon-plus"></i>
                                        </span>
                                        <span v-else class="addIcon" @click="addSpace(1, index)">
                                            <i class="el-icon-minus"></i>
                                        </span>
                                        <!--                                        <span-->
                                        <!--                                                style="padding-left: 8px"-->
                                        <!--                                                v-if="item.height && thickness_list.length == 0"-->
                                        <!--                                        >-->
                                        <!--                                            <el-button v-if="item.show" @click="item.show = false" type="text"-->
                                        <!--                                            >收起</el-button-->
                                        <!--                                            >-->
                                        <!--                                            <el-button v-else @click="item.show = true" type="text">展开</el-button>-->
                                        <!--                                        </span>-->
                                    </el-col>
                                </el-row>
                                <!--                                <el-row v-if="item.show">-->
                                <!--                                    <el-form-item label="可匹配基层：" style="width:100%" label-width="100px">-->
                                <!--                                        <el-switch-->
                                <!--                                                v-model="item.select1"-->
                                <!--                                                @change="changeSelect1(item.select1, index)"-->
                                <!--                                        ></el-switch>-->
                                <!--                                    </el-form-item>-->
                                <!--                                    <el-form-item label=" " style="width:100%" label-width="100px">-->
                                <!--                                        <el-checkbox-group-->
                                <!--                                                :disabled="item.select1 == false"-->
                                <!--                                                size="small"-->
                                <!--                                                v-model="item.grassroots_id"-->
                                <!--                                        >-->
                                <!--                                            <el-checkbox-->
                                <!--                                                    border-->
                                <!--                                                    @change="changeSelectGra($event, item1, index)"-->
                                <!--                                                    v-for="item1 in grassrootsList"-->
                                <!--                                                    :key="item1.id"-->
                                <!--                                                    :label="item1.id"-->
                                <!--                                            >{{ item1.thickness_name }}</el-checkbox-->
                                <!--                                            >-->
                                <!--                                        </el-checkbox-group>-->
                                <!--                                    </el-form-item>-->
                                <!--                                    <el-form-item label="可匹配龙骨：" style="width:100%" label-width="100px">-->
                                <!--                                        <el-switch-->
                                <!--                                                v-model="item.select2"-->
                                <!--                                                @change="changeSelect2(item.select2, index)"-->
                                <!--                                        ></el-switch>-->
                                <!--                                    </el-form-item>-->
                                <!--                                    <el-form-item label=" " style="width:100%" label-width="100px">-->
                                <!--                                        <el-checkbox-group-->
                                <!--                                                :disabled="item.select2 == false"-->
                                <!--                                                size="small"-->
                                <!--                                                v-model="item.keel_id"-->
                                <!--                                        >-->
                                <!--                                            <el-checkbox-->
                                <!--                                                    border-->
                                <!--                                                    @change="changeSelectKel($event, item1, index)"-->
                                <!--                                                    v-for="item1 in keelList"-->
                                <!--                                                    :key="item1.id"-->
                                <!--                                                    :label="item1.id"-->
                                <!--                                            >{{ item1.thickness_name }}</el-checkbox-->
                                <!--                                            >-->
                                <!--                                        </el-checkbox-group>-->
                                <!--                                    </el-form-item>-->
                                <!--                                    <el-form-item label="可匹配型材：" style="width:100%" label-width="100px">-->
                                <!--                                        <el-switch-->
                                <!--                                                v-model="item.select3"-->
                                <!--                                                @change="changeSelect3(item.select3, index)"-->
                                <!--                                        ></el-switch>-->
                                <!--                                    </el-form-item>-->
                                <!--                                    <el-form-item label=" " style="width:100%" label-width="100px">-->
                                <!--                                        <el-checkbox-group-->
                                <!--                                                :disabled="item.select3 == false"-->
                                <!--                                                size="small"-->
                                <!--                                                v-model="item.structure_id"-->
                                <!--                                        >-->
                                <!--                                            <el-checkbox-->
                                <!--                                                    border-->
                                <!--                                                    @change="changeSelectStr($event, item1, index)"-->
                                <!--                                                    v-for="item1 in structureList"-->
                                <!--                                                    :key="item1.id"-->
                                <!--                                                    :label="item1.id"-->
                                <!--                                            >{{ item1.thickness_name }}</el-checkbox-->
                                <!--                                            >-->
                                <!--                                        </el-checkbox-group>-->
                                <!--                                    </el-form-item>-->
                                <!--                                </el-row>-->
                            </span>
                        </el-form-item>

                        <el-form-item label="可匹配墙板厚度：" required class="specsInput"
                            v-if="panelShow && modelUploadForm.type == 2">
                            <el-row v-for="(item, index) in modelUploadForm.wall_panel_size" :key="index">
                                <span>
                                    <el-col :span="12" style="min-width: 200px">
                                        <el-form-item :prop="'wall_panel_size.' + index + '.value'"
                                            :rules="rules.wall_panel_size">
                                            <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                class="specsInput2" style="width: 200px" v-model="item.value">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <span v-if="index == 0" @click="addSpace(4)" class="addIcon"><i
                                            class="el-icon-plus"></i></span>
                                    <span v-else @click="addSpace(4, index)" class="addIcon"><i
                                            class="el-icon-minus"></i></span>
                                </span>
                            </el-row>
                        </el-form-item>

                        <el-row v-if="modelUploadForm.type == 2">
                            <el-row v-for="(item, index) in modelUploadForm.scopes" :key="index">
                                <el-form-item label="变量范围(最小)：" style="width: 100%" class="specsInput">
                                    <span>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput4" style="width: 100%"
                                                    v-model="item.minLength"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">&nbsp;</el-col>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                    v-model="item.minWidth"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">&nbsp;</el-col>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput3" style="width: 100%"
                                                    v-model="item.minHeight"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col v-if="index == 0" :span="2" @click="addSpace(3)" class="addIcon"><i
                                                class="el-icon-plus"></i></el-col>
                                        <el-col v-else :span="2" @click="addSpace(3, index)" class="addIcon"><i
                                                class="el-icon-minus"></i></el-col>
                                    </span>
                                </el-form-item>
                                <el-form-item label="变量范围(最大)：" style="width: 100%" class="specsInput">
                                    <span>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput4" style="width: 100%"
                                                    v-model="item.maxLength"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">&nbsp;</el-col>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput1" style="width: 100%"
                                                    v-model="item.maxWidth"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="2">&nbsp;</el-col>
                                        <el-col :span="6">
                                            <el-form-item>
                                                <el-input type="number" min="0" prefix-icon="el-icon-search"
                                                    suffix-icon="el-icon-search" class="specsInput3" style="width: 100%"
                                                    v-model="item.maxHeight"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </span>
                                </el-form-item>
                            </el-row>
                        </el-row>
                        <el-form-item v-if="trimPanelShow && modelUploadForm.type == 2" label="墙板裁口：" prop="cutting">
                            <el-form-item>
                                <el-row>
                                    <el-radio-group size="small" v-model="modelUploadForm.cutting">
                                        <el-radio border v-for="item in selectArray" :key="item.id" :label="item.id">{{
                                        item.name
                                        }}</el-radio>
                                    </el-radio-group>
                                </el-row>
                            </el-form-item>
                            <el-form-item v-if="modelUploadForm.cutting == 1">
                                <el-row class="second-label">
                                    <el-row style="width: 100%">
                                        <el-col :span="12">
                                            <el-form-item label="B1：" label-width="50px" class="specsInput"
                                                prop="cuttingArr.nwidth">
                                                <el-input type="number" min="0" class="specsInput1"
                                                    suffix-icon="el-icon-search" style="width: 100%"
                                                    v-model="modelUploadForm.cuttingArr.nwidth"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="B2：" label-width="50px" class="specsInput"
                                                prop="cuttingArr.wwidth">
                                                <el-input type="number" min="0" class="specsInput2"
                                                    suffix-icon="el-icon-search" style="width: 100%"
                                                    v-model="modelUploadForm.cuttingArr.wwidth"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row style="width: 100%">
                                        <el-col :span="12">
                                            <el-form-item label="B3：" label-width="50px" class="specsInput"
                                                prop="cuttingArr.width">
                                                <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                    class="specsInput1" style="width: 100%"
                                                    v-model="modelUploadForm.cuttingArr.width"></el-input>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-form-item label="B4：" label-width="50px" class="specsInput"
                                                prop="cuttingArr.deep">
                                                <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                    class="specsInput2" style="width: 100%"
                                                    v-model="modelUploadForm.cuttingArr.deep"></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                    <el-row style="width: 100%">
                                        <el-form-item label="示例：" label-width="50px">
                                            <el-row class="bImage">
                                                <el-image style="width: 380px" :src="url" :preview-src-list="srcList"
                                                    :initial-index="1">
                                                </el-image>
                                            </el-row>
                                        </el-form-item>
                                    </el-row>
                                </el-row>
                                <!--                                <el-form-item :label-width="modelUploadForm.slot > 1 ? '38px' : '0px'">-->
                                <!--                                    -->
                                <!--                                </el-form-item>-->
                                <!--                                <el-row class="bImage" style="width: 400px;">-->
                                <!--                                    <el-image-->
                                <!--                                            style="width: 330px;"-->
                                <!--                                            :src="url"-->
                                <!--                                            :preview-src-list="srcList"-->
                                <!--                                            :initial-index="1"-->
                                <!--                                    >-->
                                <!--                                    </el-image>-->
                                <!--                                </el-row>-->
                            </el-form-item>
                        </el-form-item>

                        <el-form-item v-if="modelUploadForm.type == 2" label="筛选体系：">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="relatioSystem">
                                        <el-select size="small" placeholder="请选择体系" clearable
                                            v-model="modelUploadForm.relationSystem" @change="changeClassificationId1">
                                            <el-option v-for="item in systemList" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 20px">
                                    <el-form-item prop="relationClassification">
                                        <el-select size="small" placeholder="请选择分类" :popper-append-to-body="false"
                                            clearable v-model="modelUploadForm.relationClassification"
                                            @change="changeMaterialId1">
                                            <el-option v-for="item in relationClassificationList" :key="item.id"
                                                :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6" style="padding-left: 20px" v-if="relationMaterialList.length > 0">
                                    <el-form-item prop="material">
                                        <el-select size="small" placeholder="请选择材料" @change="changeType"
                                            :popper-append-to-body="false" clearable
                                            v-model="modelUploadForm.relationMaterial">
                                            <el-option v-for="item in relationMaterialList" :key="item.id"
                                                :label="item.name" :value="item.id"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form-item>
                        <el-form-item v-if="modelUploadForm.type == 2" label="筛选品牌：">
                            <el-row>
                                <el-col :span="6">
                                    <el-form-item prop="relationBrandId">
                                        <el-select size="small" v-model="modelUploadForm.relationBrandId" clearable
                                            @change="changeType()" placeholder="请选择品牌">
                                            <el-option v-for="(item, index) in brandList" :key="index"
                                                :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-transfer v-model="modelUploadForm.reserveIdList" ref="reserve"
                                    @change="handleChange" :data="transferData" :button-texts="['删除', '选择']" :props="{
                                        key: 'id',
                                        label: 'name'
                                    }" :titles="['关联贴图列表', '已选贴图']">
                                </el-transfer>
                            </el-row>
                        </el-form-item>

                        <!--                        <el-form-item v-if="modelUploadForm.type == 1" label="贴图花纹：" prop="trimPanel">-->
                        <!--                            <el-select-->
                        <!--                                    size="small"-->
                        <!--                                    placeholder="请选贴图花纹"-->
                        <!--                                    clearable-->
                        <!--                                    v-model="modelUploadForm.trimPanel"-->
                        <!--                            >-->
                        <!--                                <el-option-->
                        <!--                                        v-for="item in trimPanelList"-->
                        <!--                                        :key="item.id"-->
                        <!--                                        :label="item.name"-->
                        <!--                                        :value="item.id"-->
                        <!--                                >-->
                        <!--                                </el-option>-->
                        <!--                            </el-select>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item v-if="trimPanelShow  && modelUploadForm.type == 2" label="关联体系：" prop="panelSystem">-->
                        <!--                            <el-select-->
                        <!--                                    multiple-->
                        <!--                                    size="small"-->
                        <!--                                    placeholder="请选择体系"-->
                        <!--                                    clearable-->
                        <!--                                    v-model="modelUploadForm.panelSystem"-->
                        <!--                            >-->
                        <!--                                <el-option-->
                        <!--                                        v-for="item in systemList"-->
                        <!--                                        :disabled="modelUploadForm.system == item.id"-->
                        <!--                                        :key="item.id"-->
                        <!--                                        :label="item.name"-->
                        <!--                                        :value="item.id"-->
                        <!--                                >-->
                        <!--                                </el-option>-->
                        <!--                            </el-select>-->
                        <!--                        </el-form-item>-->
                        <el-form-item v-if="structuralMemberShow && modelUploadForm.type == 2" label="是否需要暗光源："
                            prop="lightSource">
                            <el-radio-group size="small" v-model="modelUploadForm.lightSource">
                                <el-radio border v-for="item in selectArray" :key="item.id" :label="item.id">{{
                                item.name
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item v-if="structuralMemberShow && modelUploadForm.type == 2" label="有无插槽："
                            prop="slot">
                            <el-select size="small" placeholder="请选择有无插槽" clearable v-model="modelUploadForm.slot"
                                @change="changeslot">
                                <el-option v-for="item in slotList" :key="item.status" :label="item.name"
                                    :value="item.status">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item v-if="
                            structuralMemberShow && modelUploadForm.slotArr.length > 0 && modelUploadForm.type == 2
                        ">
                            <el-row>
                                <el-row class="second-label">
                                    <el-row style="width: 100%" v-for="(item, index) in modelUploadForm.slotArr"
                                        :key="index">
                                        <el-form-item :label="modelUploadForm.slot > 1 ? '侧' + (index + 1) + '：' : ''"
                                            :label-width="modelUploadForm.slot > 1 ? '38px' : '0px'" class="specsInput">
                                            <el-row style="display: inline-block; width: 100%">
                                                <el-row>
                                                    <el-col :span="12">
                                                        <el-form-item label="A1：" :prop="'slotArr.' + index + '.nwidth'"
                                                            :rules="rules.nwidth" label-width="50px" class="specsInput">
                                                            <el-input type="number" min="0" class="specsInput1"
                                                                @change="changeA1(index, 1)"
                                                                suffix-icon="el-icon-search" style="width: 100%"
                                                                v-model="item.nwidth"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <el-form-item label="A2：" :prop="'slotArr.' + index + '.wwidth'"
                                                            :rules="rules.wwidth" label-width="50px" class="specsInput">
                                                            <el-input type="number" min="0" class="specsInput2"
                                                                @change="changeA1(index, 2)"
                                                                suffix-icon="el-icon-search" style="width: 100%"
                                                                v-model="item.wwidth"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                                <el-row>
                                                    <el-col :span="12">
                                                        <el-form-item label="A3：" :prop="'slotArr.' + index + '.width'"
                                                            :rules="rules.width" label-width="50px" class="specsInput">
                                                            <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                                @change="changeA1(index, 3)" class="specsInput1"
                                                                style="width: 100%" v-model="item.width"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                    <el-col :span="12">
                                                        <el-form-item label="A4：" :prop="'slotArr.' + index + '.deep'"
                                                            :rules="rules.deep" label-width="50px" class="specsInput">
                                                            <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                                @change="changeA1(index, 4)" class="specsInput2"
                                                                style="width: 100%" v-model="item.deep"></el-input>
                                                        </el-form-item>
                                                    </el-col>
                                                </el-row>
                                            </el-row>
                                        </el-form-item>
                                    </el-row>
                                    <el-row style="width: 100%">
                                        <el-form-item :label-width="modelUploadForm.slot > 1 ? '38px' : '0px'">
                                            <el-form-item label="示例：" label-width="55px">
                                                <el-row class="bImage">
                                                    <el-image style="width: 380px" :src="url1"
                                                        :preview-src-list="srcList1" :initial-index="1">
                                                    </el-image>
                                                </el-row>
                                            </el-form-item>
                                        </el-form-item>
                                    </el-row>
                                </el-row>
                            </el-row>
                        </el-form-item>
                        <el-form-item label="适配板宽：" required class="specsInput" v-if="
                            structuralMemberShow && modelUploadForm.type == 2 && modelUploadForm.slotArr.length == 0
                        ">
                            <el-row v-for="(item, index) in modelUploadForm.adaptation_board_width" :key="index">
                                <span>
                                    <el-col :span="12" style="min-width: 200px">
                                        <el-form-item :prop="'adaptation_board_width.' + index + '.value'"
                                            :rules="rules.adaptation_board_width">
                                            <el-input type="number" min="0" suffix-icon="el-icon-search"
                                                class="specsInput2" style="width: 200px" v-model="item.value">
                                            </el-input>
                                        </el-form-item>
                                    </el-col>
                                    <span v-if="index == 0" @click="addSpace(2)" class="addIcon"><i
                                            class="el-icon-plus"></i></span>
                                    <span v-else @click="addSpace(2, index)" class="addIcon"><i
                                            class="el-icon-minus"></i></span>
                                </span>
                            </el-row>
                        </el-form-item>
                        <!--                        <el-form-item v-if="structuralMemberShow  && modelUploadForm.type == 2" label="颜色：" prop="color">-->
                        <!--                            <el-select-->
                        <!--                                    multiple-->
                        <!--                                    size="small"-->
                        <!--                                    placeholder="请选择颜色"-->
                        <!--                                    clearable-->
                        <!--                                    v-model="modelUploadForm.color"-->
                        <!--                            >-->
                        <!--                                <el-option-->
                        <!--                                        v-for="item in colorList"-->
                        <!--                                        :key="item.id"-->
                        <!--                                        :label="item.name"-->
                        <!--                                        :value="item.id"-->
                        <!--                                >-->
                        <!--                                </el-option>-->
                        <!--                            </el-select>-->
                        <!--                        </el-form-item>-->
                        <!--                        <el-form-item v-if="structuralMemberShow  && modelUploadForm.type == 2" label="关联体系：" prop="structSystem">-->
                        <!--                            <div>-->
                        <!--                                <el-cascader-->
                        <!--                                        :props="{-->
                        <!--                                        label: 'name',-->
                        <!--                                        value: 'id',-->
                        <!--                                        children: 'materialList',-->
                        <!--                                        disabled:'disabled',-->
                        <!--                                        multiple: true-->
                        <!--                                    }"-->
                        <!--                                        ref="cascader"-->
                        <!--                                        :options="systemListF"-->
                        <!--                                        v-model="modelUploadForm.structSystem"-->
                        <!--                                        :clearable="true"-->
                        <!--                                ></el-cascader>-->
                        <!--                            </div>-->
                        <!--                        </el-form-item>-->
                        <el-form-item v-if="structuralMemberShow && modelUploadForm.type == 2" label="上传CAD节点文件："
                            prop="nodeFile">
                            <el-row>
                                <el-upload :limit="1" action="" :file-list="modelUploadForm.nodeFile"
                                    :auto-upload="false" accept=".dwg,.dxf,DWG,.DXF" :on-change="handPreview"
                                    :on-remove="handleRemove">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            文件必须为dwg或dxf格式，大小3MB以内，建议上传dwg格式。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                        <el-form-item v-if="modelUploadForm.type == 2" label="CAD三视图：" prop="threeViews">
                            <el-row>
                                <el-upload :limit="1" action="" :file-list="modelUploadForm.threeViews"
                                    :auto-upload="false" accept=".dwg,.dxf,DWG,.DXF" :on-change="threePreview"
                                    :on-remove="threeRemove">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            文件必须为dwg或dxf格式，大小3MB以内，建议上传dwg格式。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                        <el-form-item v-if="wareShow && modelUploadForm.type == 2" label="CAD标识图：" prop="idenViews">
                            <el-row>
                                <el-upload :limit="1" action="" :file-list="modelUploadForm.idenViews"
                                    :auto-upload="false" accept=".dwg,.dxf,DWG,.DXF" :on-change="idenPreview"
                                    :on-remove="idenRemove">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            文件必须为dwg或dxf格式，大小3MB以内，建议上传dwg格式。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                        <el-form-item v-if="(trimPanelShow || structuralMemberShow) && modelUploadForm.type == 2"
                            label="上传CAD截面文件：" prop="sectionFile">
                            <el-row>
                                <el-upload :limit="1" action="" accept=".dwg,.dxf,DWG,.DXF"
                                    :file-list="modelUploadForm.sectionFile" :auto-upload="false"
                                    :on-change="handPreview1" :on-remove="handleRemove1">
                                    <el-button type="primary" size="mini">点击上传</el-button>
                                    <template #tip>
                                        <div class="el-upload__tip">
                                            文件必须为dwg或dxf格式，大小3MB以内，建议上传dwg格式。
                                        </div>
                                    </template>
                                </el-upload>
                            </el-row>
                        </el-form-item>
                        <el-form-item style="
                                border-bottom: 1px solid #dcdfe6;
                                border-top: 1px solid #dcdfe6;
                                background-color: #ffffff;
                                width: calc(100vw);
                                padding: 15px;
                                position: fixed;
                                bottom: 0px;
                                left: 200px;
                                z-index: 1009;
                                text-align: center;
                            ">
                            <el-row>
                                <el-col :span="13" :offset="2">
                                    <el-button style="margin-left: 10px !important" type="primary" size="small"
                                        @click="handleSaveUploadModelbtn('modelUploadForm')">{{ buttonString }}
                                    </el-button>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-form>
                </el-col>
            </el-row>
        </div>
        <edit-model :url="diaUrl" :dialogVisible="dialogModelEditVisible" v-if="dialogModelEditVisible"
            @closeDialog="closeDialog"></edit-model>
    </div>
</template>
<script>
import jsonTool from '@/utils/jsonTool.js';
import {
    getAttributesData,
    uploadAddModelAction,
    getAttributesGetModel,
    modelNameRecheckAction,
    obtainRelation,
    getModelDetail
} from '@/common/api/model/modelUpload/index.js';
import EditModel from '@/views/model/modelUpload/components/editModel';
import { parameterList } from '@/common/api/member/upload';
import { modelEditAction } from '../common/api/model/modelLibrary';
export default {
    name: 'ModelUploadCom',
    components: { EditModel },
    props: {
        title: {
            type: String,
            default: null
        },
        isEdit: {
            type: Boolean,
            default: false
        },
        modelId: {
            type: Number,
            default: null
        },
        buttonString: {
            type: String,
            default: null
        },
        listType: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            arr: [],
            transferData: [],
            diaUrl: '',
            url: require('@/assets/b.png'),
            url1: require('@/assets/a.png'),
            srcList: [require('@/assets/b.png')],
            srcList1: [require('@/assets/a.png')],
            brandSeries_list: [],
            selectArray: [
                {
                    id: 1,
                    name: '是'
                },
                {
                    id: 0,
                    name: '否'
                }
            ],
            slotList: [],
            // grassrootsList:[],
            // keelList:[],
            // thickness_list:[],
            structureList: [],
            colorList: [],
            trimPanelList: [],
            uploadFileLoaing: false,
            trimPanelShow: false,
            structuralMemberShow: false,
            dialogModelEditVisible: false,
            panelShow: false,
            wareShow: false,
            // 保存上传file类型的数据
            fileList: [],
            beforeName: '',
            modelUploadForm: {
                // 体系
                system: '',
                relationSystem: '',
                // 分类
                classification: '',
                relationClassification: '',
                // 材料
                material: '',
                relationMaterial: '',
                // 品牌id
                brandId: '',
                relationBrandId: '',
                reserveIdList: [],
                series_id: '',
                // 类型
                type: '',
                // 模型名称
                modelName: '',
                modelNumber: '',
                // 上传文件
                file: [],
                cutting: 1,
                cuttingArr: {
                    nwidth: '',
                    wwidth: '',
                    width: '',
                    deep: ''
                },
                // panelSystem:[],
                sectionFile: [],
                nodeFile: [],
                threeViews: [],
                idenViews: [],
                lightSource: 1,
                slot: 2,
                slotArr: [],
                color: [],
                // structSystem:[],
                adaptation_board_width: [{ value: '' }],
                wall_panel_size: [{ value: '' }],
                format: {
                    status: 0,
                    // showSelect:false,
                    arr: [
                        {
                            id: '',
                            select1: true,
                            select2: true,
                            select3: true,
                            grassroots_id: [],
                            keel_id: [],
                            structure_id: [],
                            show: false,
                            length: '',
                            width: '',
                            height: ''
                        }
                    ]
                },
                scopes: [
                    {
                        minLength: '',
                        minWidth: '',
                        minHeight: '',
                        maxLength: '',
                        maxWidth: '',
                        maxHeight: ''
                    }
                ],
                trimPanel: ''
            },
            // 模型名称错误信息提示
            errorMessage: '',
            errorMessage1: '',
            rules: {
                system: [{ required: true, message: '请选择体系', trigger: 'change' }],
                classification: [{ required: true, message: '请选择分类', trigger: 'change' }],
                type: [{ required: true, message: '请选择类型', trigger: 'change' }],
                modelName: [
                    { required: true, message: '请输入模型名称', trigger: 'blur' }
                    // {
                    //     pattern: /^[\u4E00-\u9FA5A-Za-z0-9_]+$/,
                    //     message: '只允许输入：字母/数字/汉字/下划线',
                    //     trigger: 'blur'
                    // }
                ],
                modelNumber: [{ required: true, message: '请输入模型编号', trigger: 'blur' }],
                brandId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
                cutting: [{ required: true, message: '请选择墙板裁口', trigger: 'change' }],
                lightSource: [{ required: true, message: '请选择是否需要暗光源', trigger: 'change' }],
                slot: [{ required: true, message: '请选择有无插槽', trigger: 'change' }],
                color: [{ required: true, message: '请选择颜色', trigger: 'change' }],
                'cuttingArr.nwidth': [{ required: true, message: '请输入B1', trigger: 'blur' }],
                'cuttingArr.wwidth': [{ required: true, message: '请输入B2', trigger: 'blur' }],
                'cuttingArr.width': [{ required: true, message: '请输入B3', trigger: 'blur' }],
                'cuttingArr.deep': [{ required: true, message: '请输入B4', trigger: 'blur' }],
                trimPanel: [{ required: true, message: '请选择贴图花纹', trigger: 'change' }],
                // panelSystem:[{ required: true, message: '请选择关联体系', trigger: 'change' }],
                sectionFile: [{ required: true, message: '请上传CAD截面文件', trigger: 'blur' }],
                file: [{ required: true, message: '请上传文件', trigger: 'blur' }],
                nodeFile: [{ required: true, message: '请上传CAD节点文件', trigger: 'blur' }],
                threeViews: [{ required: true, message: '请上传CAD三视图', trigger: 'blur' }],
                idenViews: [{ required: true, message: '请上传CAD标识图', trigger: 'blur' }],
                // structSystem: [{ required: true, message: '请选择关联体系', trigger: 'change' }],
                nwidth: [{ required: true, message: '请输入A1', trigger: 'blur' }],
                wwidth: [{ required: true, message: '请输入A2', trigger: 'blur' }],
                width: [{ required: true, message: '请输入A3', trigger: 'blur' }],
                deep: [{ required: true, message: '请输入A4', trigger: 'blur' }],
                adaptation_board_width: [{ required: true, message: '请输入适配板宽', trigger: 'blur' }],
                wall_panel_size: [{ required: true, message: '请输入可匹配墙板厚度', trigger: 'blur' }],
                format: [
                    { required: true, message: '请输入长', trigger: 'blur' },
                    { required: true, message: '请输入宽', trigger: 'blur' },
                    { required: true, message: '请输入厚', trigger: 'blur' }
                ]
            },
            // 系统列表
            systemList: [],
            // systemListF:[],
            // 分类列表
            classificationList: [],
            relationClassificationList: [],
            // 材料列表
            materialList: [],
            relationMaterialList: [],
            // 品牌列表
            brandList: [],
            // 类型列表
            typeList: [
                {
                    id: 1,
                    name: '贴图'
                },
                {
                    id: 2,
                    name: '模型'
                }
            ],
            deleteImgArr: []
        };
    },
    mounted() {
        this.queryAttributesData();
    },
    methods: {
        getModelDetail() {
            let dt = {
                id: this.modelId,
                list_type: this.listType
            };
            getModelDetail(dt).then(res => {
                let { code, result } = res;
                this.modelUploadForm.system = result.setup_id;
                this.changeClassificationId(result.classify_id, result.son_classify_id);
                this.modelUploadForm.brandId = result.brand_id;
                this.changeBrand(false, result.series_id);
                this.modelUploadForm.type = result.type;
                this.modelUploadForm.modelName = result.name;
                this.beforeName = result.name;
                this.modelUploadForm.modelNumber = result.number;
                if (this.modelUploadForm.type == 2) {
                    if (result.format.format.length) {
                        this.modelUploadForm.format.arr = [];
                    }
                    result.format.format.forEach(element => {
                        let tmp = {
                            id: '',
                            select1: true,
                            select2: true,
                            select3: true,
                            grassroots_id: [],
                            keel_id: [],
                            structure_id: [],
                            show: false,
                            length: parseInt(element.length),
                            width: parseInt(element.width),
                            height: parseInt(element.height)
                        };
                        this.modelUploadForm.format.arr.push(tmp);
                    });
                    this.modelUploadForm.format.status = parseInt(result.format.status);
                    if (result.least_scope.length) {
                        this.modelUploadForm.scopes = [];
                        let min = result.least_scope;
                        let max = result.maximum_scope;
                        min.forEach((item, index) => {
                            let tt = {
                                minLength: item.length,
                                minWidth: item.width,
                                minHeight: item.height,
                                maxLength: max[index].length,
                                maxWidth: max[index].width,
                                maxHeight: max[index].height
                            };
                            this.modelUploadForm.scopes.push(tt);
                        });
                    }
                    if (this.trimPanelShow) {
                        //判断为饰面板
                        //墙板裁口
                        if (result.wall_panel_cutout.length) {
                            this.modelUploadForm.cutting = 1;
                            this.modelUploadForm.cuttingArr = {
                                nwidth: result.wall_panel_cutout[0].B1,
                                wwidth: result.wall_panel_cutout[0].B2,
                                width: result.wall_panel_cutout[0].B3,
                                deep: result.wall_panel_cutout[0].B4
                            };
                        } else {
                            this.modelUploadForm.cutting = 0;
                        }
                        //截面文件
                        if (result.cad_section_file) {
                            let num = result.cad_section_file.lastIndexOf('/');
                            let name = result.cad_section_file.substring(num + 1);
                            this.modelUploadForm.sectionFile[0] = {
                                url: result.cad_section_file,
                                name: name
                            };
                        }
                    }

                    if (this.structuralMemberShow) {
                        //判断为结构件
                        //是否需要暗光源
                        this.modelUploadForm.lightSource = result.is_dark_light;
                        //有无插槽
                        this.modelUploadForm.slot = parseInt(result.groove.type);
                        this.changeslot(this.modelUploadForm.slot);
                        if (
                            parseInt(this.modelUploadForm.slot) == 0 &&
                            result.adaptation_board_width &&
                            result.adaptation_board_width.length
                        ) {
                            this.modelUploadForm.adaptation_board_width = [];
                            for (var d = 0; d < result.adaptation_board_width.length; d++) {
                                this.modelUploadForm.adaptation_board_width.push({
                                    value: result.adaptation_board_width[d]
                                });
                            }
                        } else {
                            this.modelUploadForm.slotArr = result.groove.medial_list.map(ele => {
                                return {
                                    nwidth: ele.A1,
                                    wwidth: ele.A2,
                                    width: ele.A3,
                                    deep: ele.A4
                                };
                            });
                        }
                        //颜色
                        // for(var z=0;z<result.color.length;z++){
                        //     this.modelUploadForm.color.push(result.color[z]);
                        // }
                        //截面文件
                        let num = result.cad_section_file.lastIndexOf('/');
                        let name = result.cad_section_file.substring(num + 1);
                        this.modelUploadForm.sectionFile[0] = {
                            url: result.cad_section_file,
                            name: name
                        };
                        //节点文件
                        let num1 = result.cad_node_file.lastIndexOf('/');
                        let name1 = result.cad_node_file.substring(num1 + 1);
                        this.modelUploadForm.nodeFile[0] = {
                            url: result.cad_node_file,
                            name: name1
                        };
                    }

                    //关联的贴图
                    for (var y = 0; y < result.relation_chartlet.length; y++) {
                        this.transferData.push(result.relation_chartlet[y]);
                    }
                    this.$nextTick(() => {
                        for (var f = 0; f < this.transferData.length; f++) {
                            this.$refs.reserve.leftChecked.push(this.transferData[f].id);
                        }
                        this.$refs.reserve.addToRight();
                    });
                } else {
                    if (result.texture_pattern) {
                        for (var x = 0; x < result.texture_pattern.length; x++) {
                            this.modelUploadForm.trimPanel.push(result.texture_pattern[x]);
                        }
                    }
                }
                if (this.panelShow) {
                    if (result.wall_panel_size && result.wall_panel_size.length) {
                        this.modelUploadForm.wall_panel_size = [];
                        for (var f = 0; f < result.wall_panel_size.length; f++) {
                            this.modelUploadForm.wall_panel_size.push({
                                value: result.wall_panel_size[f]
                            });
                        }
                    }
                }
                if (this.wareShow && result.cad_iden_file) {
                    let num3 = result.cad_iden_file.lastIndexOf('/');
                    let name3 = result.cad_iden_file.substring(num3 + 1);
                    this.modelUploadForm.idenViews[0] = {
                        url: result.cad_iden_file,
                        name: name3
                    };
                }
                if (result.cad_three_file) {
                    let num2 = result.cad_three_file.lastIndexOf('/');
                    let name2 = result.cad_three_file.substring(num2 + 1);
                    this.modelUploadForm.threeViews[0] = {
                        url: result.cad_three_file,
                        name: name2
                    };
                }
            });
        },
        changeType() {
            // console.log()
            if (
                this.modelUploadForm.type == 2 &&
                (this.modelUploadForm.relationSystem || this.modelUploadForm.relationBrandId)
            ) {
                let dt = {
                    setup_id: this.modelUploadForm.relationSystem,
                    classify_id: this.modelUploadForm.relationClassification,
                    son_classify_id: this.modelUploadForm.relationMaterial,
                    brand_id: this.modelUploadForm.relationBrandId,
                    type: this.modelUploadForm.type
                };
                obtainRelation(dt).then(res => {
                    this.transferData = res.result;
                });
            }
        },
        handleChange(value, direction) {
            // console.log(this.modelUploadForm.reserveIdList);
            // this.modelUploadForm.reserveIdList.push(value);
        },
        changeBrand(type, id) {
            if (type) {
                this.modelUploadForm.series_id = '';
                this.brandSeries_list = [];
            }
            this.brandList.forEach(ele => {
                if (this.modelUploadForm.brandId == ele.id) {
                    // this.modelUploadForm.brand_name = ele.b_name;
                    this.brandSeries_list = ele.brandSeriesList;
                }
            });
            if (id) {
                this.modelUploadForm.series_id = id;
            }
        },
        // changeSelectStr(val, item, index) {
        //     if (val) {
        //         if (!this.arr[index]) {
        //             this.$message.error('该尺寸未匹配到型材，请去添加');
        //             return false;
        //         }
        //         let status = true;
        //         this.arr[index].structure.forEach(ele => {
        //             if (parseInt(ele.structure_id) == item.id) {
        //                 status = false;
        //             }
        //         });
        //         if (status) {
        //             this.$message.error('该尺寸未匹配到型材，请去添加');
        //         }
        //     }
        // },
        // changeSelectKel(val, item, index) {
        //     if (val) {
        //         if (!this.arr[index]) {
        //             this.$message.error('该尺寸未匹配到龙骨，请去添加');
        //             return false;
        //         }
        //         let status = true;
        //         this.arr[index].keel.forEach(ele => {
        //             if (parseInt(ele.keel_id) == item.id) {
        //                 status = false;
        //             }
        //         });
        //         if (status) {
        //             this.$message.error('该尺寸未匹配到龙骨，请去添加');
        //         }
        //     }
        // },
        changeA1(index, type) {
            if (this.modelUploadForm.slotArr.length > 1) {
                if (index == 0) {
                    if (type == 1) {
                        this.modelUploadForm.slotArr[1].nwidth = this.modelUploadForm.slotArr[0].nwidth;
                    }
                    if (type == 2) {
                        this.modelUploadForm.slotArr[1].wwidth = this.modelUploadForm.slotArr[0].wwidth;
                    }
                    if (type == 3) {
                        this.modelUploadForm.slotArr[1].width = this.modelUploadForm.slotArr[0].width;
                    }
                    if (type == 4) {
                        this.modelUploadForm.slotArr[1].deep = this.modelUploadForm.slotArr[0].deep;
                    }
                }
            } else {
                return false;
            }
        },
        changeHeight(val, index, type, clear = true) {
            if (this.modelUploadForm.classification) {
                let tmpId = '';
                this.classificationList.forEach(element => {
                    if (this.modelUploadForm.classification == element.id) {
                        //墙板情况下匹配厚度数值
                        if (this.confirmType.indexOf(element.material_name) < 0) {
                            element.thicknessList.forEach(ele => {
                                if (parseInt(ele.thicknessSize) == val) {
                                    tmpId = val;
                                    if (ele.wildcardList.length) {
                                        let tmp = {
                                            grassroots: ele.wildcardList[0].grassroots
                                                ? ele.wildcardList[0].grassroots
                                                : [],
                                            keel: ele.wildcardList[0].keel ? ele.wildcardList[0].keel : [],
                                            structure: ele.wildcardList[0].structure
                                                ? ele.wildcardList[0].structure
                                                : []
                                        };
                                        this.modelUploadForm.format.arr[index].select1 = true;
                                        this.modelUploadForm.format.arr[index].select2 = true;
                                        this.modelUploadForm.format.arr[index].select3 = true;
                                        this.arr[index] = tmp;
                                    } else {
                                        let tmp = {
                                            grassroots: [],
                                            keel: [],
                                            structure: []
                                        };
                                        this.arr[index] = tmp;
                                    }
                                    this.modelUploadForm.format.arr[index].id = ele.id;
                                }
                            });
                        } else {
                            //非墙板下不匹配直接输入厚度数值
                            tmpId = 99;
                        }
                    }
                });
                if (tmpId == '') {
                    if (!type) {
                        // this.$message.error('没有匹配到对应的厚度配置，请去添加!');
                    }
                    if (this.arr[index]) {
                        this.arr.splice(index, 1);
                    }
                    this.modelUploadForm.format.arr[index].id = '';
                }
            } else {
                this.$message.error('请先选择构件类型');
                this.modelUploadForm.format.arr[index].height = '';
            }
            if (clear) {
                this.modelUploadForm.format.arr[index].grassroots_id = [];
                this.modelUploadForm.format.arr[index].keel_id = [];
                this.modelUploadForm.format.arr[index].structure_id = [];
            }
        },
        // changeSelect1(val, index) {
        //     if (val == false) {
        //         this.modelUploadForm.format.arr[index].grassroots_id = [];
        //     }
        // },
        // changeSelect2(val, index) {
        //     if (val == false) {
        //         this.modelUploadForm.format.arr[index].keel_id = [];
        //     }
        // },
        // changeSelect3(val, index) {
        //     if (val == false) {
        //         this.modelUploadForm.format.arr[index].structure_id = [];
        //     }
        // },
        changeHeightAll() {
            let all = this.modelUploadForm.format.arr;
            all.forEach((item, index) => {
                this.changeHeight(all[index].height, index, 1, false);
            });
        },
        // changeSelectGra(val, item, index) {
        //     if (val) {
        //         if (!this.arr[index]) {
        //             this.$message.error('该尺寸未匹配到基层，请去添加');
        //             return false;
        //         }
        //         let status = true;
        //         this.arr[index].grassroots.forEach(ele => {
        //             if (parseInt(ele.grassroots_id) == item.id) {
        //                 status = false;
        //             }
        //         });
        //         if (status) {
        //             this.$message.error('该尺寸未匹配到基层，请去添加');
        //         }
        //     }
        // },
        addSpace(type, index) {
            if (type == 1) {
                if (index) {
                    //减去
                    this.modelUploadForm.format.arr.splice(index, 1);
                } else {
                    this.modelUploadForm.format.arr.push({
                        id: '',
                        select1: true,
                        select2: true,
                        select3: true,
                        grassroots_id: [],
                        keel_id: [],
                        structure_id: [],
                        show: false,
                        length: '',
                        width: '',
                        height: ''
                    });
                }
            }
            if (type == 2) {
                if (index) {
                    //减去
                    this.modelUploadForm.adaptation_board_width.splice(index, 1);
                } else {
                    this.modelUploadForm.adaptation_board_width.push({ value: '' });
                }
            }
            if (type == 3) {
                if (index) {
                    //减去
                    this.modelUploadForm.scopes.splice(index, 1);
                } else {
                    this.modelUploadForm.scopes.push({
                        minLength: '',
                        minWidth: '',
                        minHeight: '',
                        maxLength: '',
                        maxWidth: '',
                        maxHeight: ''
                    });
                }
            }
            if (type == 4) {
                if (index) {
                    this.modelUploadForm.wall_panel_size.splice(index, 1);
                } else {
                    this.modelUploadForm.wall_panel_size.push({ value: '' });
                }
            }
        },
        changeslot(val) {
            if (val > 0) {
                let tmp = [];
                for (var i = 0; i < val; i++) {
                    tmp.push({
                        nwidth: '',
                        wwidth: '',
                        width: '',
                        deep: ''
                    });
                }
                this.modelUploadForm.slotArr = tmp;
                this.modelUploadForm.adaptation_board_width = [{ value: '' }];
            } else {
                this.modelUploadForm.slotArr = [];
            }
        },
        handPreview(file, fileList) {
            let isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 3MB!');
                this.modelUploadForm.nodeFile = [];
                return false;
            }
            this.modelUploadForm.nodeFile = fileList;
            this.$refs['modelUploadForm'].validateField('nodeFile');
        },
        threePreview(file, fileList) {
            let isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 3MB!');
                this.modelUploadForm.threeViews = [];
                return false;
            }
            this.modelUploadForm.threeViews = fileList;
            this.$refs['modelUploadForm'].validateField('threeViews');
        },
        idenPreview(file, fileList) {
            let isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 3MB!');
                this.modelUploadForm.idenViews = [];
                return false;
            }
            this.modelUploadForm.idenViews = fileList;
            this.$refs['modelUploadForm'].validateField('idenViews');
        },
        handPreview1(file, fileList) {
            let isLt2M = file.size / 1024 / 1024 < 3;
            if (!isLt2M) {
                this.$message.error('上传文件大小不能超过 3MB!');
                this.modelUploadForm.sectionFile = [];
                return false;
            }
            this.modelUploadForm.sectionFile = fileList;
            this.$refs['modelUploadForm'].validateField('sectionFile');
        },
        handleRemove(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url);
            }
            this.modelUploadForm.nodeFile = fileList;
            this.$refs['modelUploadForm'].validateField('nodeFile');
            // console.log(this.deleteImgArr);
        },
        threeRemove(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url);
            }
            this.modelUploadForm.threeViews = fileList;
            this.$refs['modelUploadForm'].validateField('threeViews');
            // console.log(this.deleteImgArr);
        },
        idenRemove(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url);
            }
            this.modelUploadForm.idenViews = fileList;
            this.$refs['modelUploadForm'].validateField('idenViews');
            // console.log(this.deleteImgArr);
        },
        handleRemove1(file, fileList) {
            if (!file.raw) {
                this.deleteImgArr.push(file.url);
            }
            this.modelUploadForm.sectionFile = fileList;
            this.$refs['modelUploadForm'].validateField('sectionFile');
            // console.log(this.deleteImgArr);
        },
        closeDialog(val) {
            this.dialogModelEditVisible = val;
            if (this.isEdit) {
                this.$parent.handleClose();
            }
        },
        change() {
            // console.log(this.modelUploadForm.brandId);
        },
        // 查询体系/分类/材料/品牌数据
        queryAttributesData() {
            getAttributesData()
                .then(res => {
                    // console.log(res);
                    let { code, result } = res;
                    if (code === 200) {
                        let newResult = jsonTool(result);
                        // this.systemList = newResult.setupList.map(item => {
                        //     return {
                        //         id: item.id,
                        //         name: item.setupName,
                        //         materialList: item.materialList
                        //     };
                        // });
                        // console.log(this.systemList);
                        // this.systemListF = newResult.setupList.map(item => {
                        //     if (item.materialList){
                        //         item.materialList = item.materialList.map( i => {
                        //             return {
                        //                 id:i.id,
                        //                 name:i.materialName,
                        //                 disabled:false
                        //             }
                        //         } )
                        //     }
                        //     return {
                        //         id: item.id,
                        //         name: item.setupName,
                        //         materialList: item.materialList
                        //     };
                        // });
                        this.brandList = newResult.brandList.map(item => {
                            return {
                                id: item.bId,
                                name: item.bName,
                                brandSeriesList: item.brandSeriesList
                            };
                        });
                        this.slotList = result.slot_list;
                        this.colorList = result.color_list;
                        this.trimPanelList = result.texturePattern;
                    }
                    if (this.modelId) {
                        this.getModelDetail();
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
            getAttributesGetModel()
                .then(res => {
                    let newResult = jsonTool(res);
                    this.systemList = newResult.setupList.map(item => {
                        return {
                            id: item.id,
                            name: item.setupName,
                            materialList: item.materialList
                        };
                    });
                    // console.log(this.systemList);
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        handleClose() {
            if (this.isEdit) {
                this.$parent.handleClose();
            }
        },
        // 处理选择分类
        changeClassificationId(id, sonId) {
            // this.modelUploadForm.panelSystem = [];
            this.systemList.forEach(ele => {
                if (ele.id === this.modelUploadForm.system) {
                    this.classificationList = ele.materialList.map(item => {
                        return {
                            id: item.id,
                            name: item.materialName,
                            thicknessList: item.thicknessList
                        };
                    });
                    // console.log('分类this.classificationList: ', this.classificationList);
                }
            });
            this.modelUploadForm.classification = '';
            this.modelUploadForm.material = '';
            this.materialList = [];
            let dt = {
                setup_id: this.modelUploadForm.system
            };
            parameterList(dt).then(res => {
                this.grassrootsList = res.result.grassrootsList;
                this.keelList = res.result.keelList;
                this.structureList = res.result.structureList;
            });
            if (id) {
                this.modelUploadForm.classification = parseInt(id);
                this.changeMaterialId(sonId);
            }
        },
        // 处理选择分类
        changeClassificationId1() {
            this.systemList.forEach(ele => {
                if (ele.id === this.modelUploadForm.relationSystem) {
                    this.relationClassificationList = ele.materialList.map(item => {
                        return {
                            id: item.id,
                            name: item.materialName,
                            thicknessList: item.thicknessList
                        };
                    });
                }
            });
            this.modelUploadForm.relationClassification = '';
            this.modelUploadForm.relationMaterial = '';
            this.relationMaterialList = [];
            this.changeType();
            // let dt = {
            //     setup_id: this.modelUploadForm.system
            // };
            // parameterList(dt).then(res => {
            //     this.grassrootsList = res.result.grassrootsList;
            //     this.keelList = res.result.keelList;
            //     this.structureList = res.result.structureList;
            // });
        },
        // 处理选择材料
        changeMaterialId1() {
            this.relationClassificationList.forEach(ele => {
                if (ele.id === this.modelUploadForm.relationClassification) {
                    let arr = [];
                    ele.thicknessList.forEach(v => {
                        if (Number(v.thicknessSize) === 0) {
                            arr.push(v);
                        }
                    });
                    this.relationMaterialList = arr.map(item => {
                        return {
                            id: item.id,
                            name: item.thicknessName,
                            wildcardList: item.wildcardList,
                            thicknessSize: item.thicknessSize
                        };
                    });
                    // console.log('材料this.materialList: ', this.materialList);
                }
            });
            this.modelUploadForm.relationMaterial = '';
            this.changeType();
        },
        // 处理选择材料
        changeMaterialId(id) {
            this.trimPanelShow = false;
            this.structuralMemberShow = false;
            this.panelShow = false;
            this.wareShow = false;
            this.modelUploadForm.wall_panel_size = [{ value: '' }];
            // this.modelUploadForm.structSystem = [];
            // this.systemListF.map(item => {
            //     if (item.materialList){
            //         item.materialList = item.materialList.map( i => {
            //             return {
            //                 id:i.id,
            //                 name:i.name,
            //                 disabled:this.modelUploadForm.classification == i.id ? true : false
            //             }
            //         } )
            //     }
            //     return {
            //         id: item.id,
            //         name: item.name,
            //         materialList: item.materialList
            //     };
            // });

            this.classificationList.forEach(ele => {
                if (ele.id === this.modelUploadForm.classification) {
                    if (ele.name.indexOf('板') != -1) {
                        // if (ele.name == '饰面板'){
                        this.trimPanelShow = true;
                    }
                    if (ele.name == '结构件' || this.wareType.indexOf(ele.name) > -1) {
                        this.structuralMemberShow = true;
                        this.changeslot(this.modelUploadForm.slot);
                    }
                    if (ele.name == '结构件' || ele.name == '龙骨') {
                        this.panelShow = true;
                    }
                    if (ele.name == '洁具') {
                        this.wareShow = true;
                    }
                    let arr = [];
                    // console.log(ele);
                    arr.push(ele);
                    // ele.thicknessList.forEach(v => {
                    // console.log(v);
                    // if (Number(v.thicknessSize) === 0) {
                    // arr.push(v);
                    // }
                    // });
                    this.materialList = arr.map(item => {
                        return {
                            id: item.id,
                            name: item.thicknessName,
                            wildcardList: item.wildcardList,
                            thicknessSize: item.thicknessSize
                        };
                    });
                    // console.log(this.materialList);
                    // console.log('材料this.materialList: ', this.materialList);
                }
            });
            this.modelUploadForm.material = '';
            // this.materialList.forEach(element => {
            //     if (this.modelUploadForm.classification == element.id) {
            //         let aa = [];
            //         let tt = element.thickness_list;
            //         console.log(element);
            //         // console.log(tt);
            //         tt.forEach(v => {
            //             if (parseInt(v.thickness_size) == 0) {
            //                 aa.push(v);
            //             }
            //         });
            //         // this.thickness_list = aa;
            //     }
            //     if (
            //         this.modelUploadForm.classification == element.id &&
            //         parseInt(this.confirmType.indexOf(element.material_name)) > -1
            //     ) {
            //         if (element.material_name == '龙骨') {
            //             this.showLong = true;
            //         }
            //     } else {
            //         if (this.modelUploadForm.classification == element.id) {
            //             this.showHu = true;
            //         }
            //         this.modelUploadForm.format = {
            //             status: 0,
            //             // showSelect:false,
            //             arr: [
            //                 {
            //                     id: '',
            //                     select1: true,
            //                     select2: true,
            //                     select3: true,
            //                     grassroots_id: [],
            //                     keel_id: [],
            //                     structure_id: [],
            //                     show: false,
            //                     length: '',
            //                     width: '',
            //                     height: ''
            //                 }
            //             ]
            //         };
            //         this.arr = [];
            //     }
            // });
            if (id) {
                this.modelUploadForm.material = parseInt(id);
            }
        },
        jump(type) {
            if (type == 1) {
                this.$router.push('/category');
            } else {
                this.$router.push('/brandManage');
            }
        },
        // 处理模型输入框失去焦点事件
        handleModelNameBlur() {
            if (!this.modelId || (this.modelId && this.modelUploadForm.modelName != this.beforeName)) {
                if (this.modelUploadForm.modelName && this.modelUploadForm.modelNumber) {
                    if (!this.modelUploadForm.brandId) {
                        this.$message.error('请先选择品牌');
                        this.modelUploadForm.modelName = '';
                        this.modelUploadForm.modelNumber = '';
                        return false;
                    }
                    let params = {
                        name: this.modelUploadForm.modelName,
                        number: this.modelUploadForm.modelNumber,
                        brand_id: this.modelUploadForm.brandId
                    };
                    modelNameRecheckAction(params)
                        .then(res => {
                            let { code, msg } = res;
                            if (code !== 200) {
                                this.$message.error(msg);
                            }
                            if (code === 444) {
                                this.errorMessage = `当前模型名称已存在，请重新输入`;
                                this.modelUploadForm.modelName = '';
                            } else {
                                this.errorMessage = '';
                            }
                            if (code === 555) {
                                this.errorMessage1 = `当前模型编号已存在，请重新输入`;
                                this.modelUploadForm.modelNumber = '';
                            } else {
                                this.errorMessage1 = '';
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                }
            }
        },
        // 处理文件上传
        handleUploadFile(file, fileList) {
            const isType =
                file.raw.type === 'image/jpeg' ||
                file.raw.type === 'image/png' ||
                file.raw.type === 'application/x-zip-compressed';
            const isLt5M = file.raw.size / 1024 / 1024 < 5;
            if (!isType) {
                fileList.pop();
                this.$message.error('请上传正确格式的文件!');
                return;
            }
            if (!isLt5M) {
                fileList.pop();
                this.$message.error('请上传图片大小不超过5MB');
                return;
            }
            this.modelUploadForm.file.push(file);

            this.$refs.uploadFileRef.clearFiles();
            this.$refs['modelUploadForm'].validateField('file');
        },
        handleUploadFileRemove(file, fileList) {
            // console.log('file: ', file);
            if (this.modelUploadForm.file.length > 0) {
                this.modelUploadForm.file = fileList;
            }
            this.$refs['modelUploadForm'].validateField('file');
        },
        handleExceed(files, fileList) {
            this.$message.error(
                `当前限制选择1个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length
                } 个文件`
            );
        },
        unique(arr) {
            if (!Array.isArray(arr)) {
                return;
            }
            var array = [];
            for (var i = 0; i < arr.length; i++) {
                if (!array.includes(arr[i])) {
                    //includes 检测数组是否有某个值
                    array.push(arr[i]);
                }
            }
            return array;
        },
        // 处理保存并上传模型事件
        handleSaveUploadModelbtn(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    let _this = this;
                    let params = new FormData();
                    if (this.modelId) {
                        params.append('id', this.modelId);
                    }
                    params.append('setup_id', this.modelUploadForm.system);
                    params.append('classify_id', this.modelUploadForm.classification);
                    params.append('son_classify_id', this.modelUploadForm.material);
                    params.append('brand_id', this.modelUploadForm.brandId);
                    params.append('series_id', this.modelUploadForm.series_id);
                    params.append('type', this.modelUploadForm.type);
                    params.append('name', this.modelUploadForm.modelName);
                    params.append('number', this.modelUploadForm.modelNumber);
                    for (let i = 0; i < this.modelUploadForm.file.length; i++) {
                        if (this.modelUploadForm.file[i].raw) {
                            params.append('model_file', this.modelUploadForm.file[i].raw);
                        }
                    }
                    if (_this.modelUploadForm.type == 2) {
                        let tmp4 = _this.modelUploadForm.format.arr;
                        let seriseArr = [];
                        params.append('format[status]', _this.modelUploadForm.format.status);
                        for (var i4 = 0; i4 < tmp4.length; i4++) {
                            params.append('format[format][' + i4 + '][length]', tmp4[i4].length);
                            params.append('format[format][' + i4 + '][width]', tmp4[i4].width);
                            params.append('format[format][' + i4 + '][height]', tmp4[i4].height);
                            // params.append('format[format][' + i4 + '][grassroots]', tmp4[i4].grassroots_id);
                            // params.append('format[format][' + i4 + '][keel]', tmp4[i4].keel_id);
                            // params.append('format[format][' + i4 + '][structure]', tmp4[i4].structure_id);
                            // let graArr = [];
                            // tmp4[i4].grassroots_id.forEach(element => {
                            //     if (_this.arr[i4]) {
                            //         _this.arr[i4].grassroots.forEach(e => {
                            //             if (parseInt(e.grassroots_id) == element) {
                            //                 graArr.push(element);
                            //             }
                            //         });
                            //     }
                            // });
                            // if (graArr.length) {
                            //     graArr.forEach((ele, index) => {
                            //         params.append(
                            //             'format[format][' +
                            //             i4 +
                            //             '][matchGrassroots][' +
                            //             index +
                            //             '][matchGrassroots_id]',
                            //             ele
                            //         );
                            //     });
                            // }

                            // let keeArr = [];
                            // tmp4[i4].keel_id.forEach(element => {
                            //     if (_this.arr[i4]) {
                            //         _this.arr[i4].keel.forEach(e => {
                            //             if (parseInt(e.keel_id) == element) {
                            //                 keeArr.push(element);
                            //             }
                            //         });
                            //     }
                            // });
                            // if (keeArr.length) {
                            //     keeArr.forEach((ele, index) => {
                            //         params.append(
                            //             'format[format][' + i4 + '][matchKell][' + index + '][matchKell_id]',
                            //             ele
                            //         );
                            //     });
                            // }
                            // let strArr = [];
                            // tmp4[i4].structure_id.forEach(element => {
                            //     if (_this.arr[i4]) {
                            //         _this.arr[i4].structure.forEach(e => {
                            //             if (parseInt(e.structure_id) == element) {
                            //                 strArr.push(element);
                            //             }
                            //         });
                            //     }
                            // });
                            // if (strArr.length) {
                            //     strArr.forEach((ele, index) => {
                            //         params.append(
                            //             'format[format][' +
                            //             i4 +
                            //             '][matchStructure][' +
                            //             index +
                            //             '][matchStructure_id]',
                            //             ele
                            //         );
                            //     });
                            // }
                            if (tmp4[i4].id) {
                                seriseArr.push(tmp4[i4].id);
                            }
                        }
                        seriseArr = _this.unique(seriseArr);
                        params.append('format[size_id]', seriseArr.toString());
                        let tmp5 = _this.modelUploadForm.scopes;
                        for (var i5 = 0; i5 < tmp5.length; i5++) {
                            params.append('least_scope[' + i5 + '][length]', tmp5[i5].minLength);
                            params.append('maximum_scope[' + i5 + '][length]', tmp5[i5].maxLength);
                            params.append('least_scope[' + i5 + '][width]', tmp5[i5].minWidth);
                            params.append('maximum_scope[' + i5 + '][width]', tmp5[i5].maxWidth);
                            params.append('least_scope[' + i5 + '][height]', tmp5[i5].minHeight);
                            params.append('maximum_scope[' + i5 + '][height]', tmp5[i5].maxHeight);
                        }

                        if (this.trimPanelShow) {
                            //判断为饰面板
                            //墙板裁口
                            if (this.modelUploadForm.cutting == 1) {
                                params.append('wall_panel_cutout[0][B1]', this.modelUploadForm.cuttingArr.nwidth);
                                params.append('wall_panel_cutout[0][B2]', this.modelUploadForm.cuttingArr.wwidth);
                                params.append('wall_panel_cutout[0][B3]', this.modelUploadForm.cuttingArr.width);
                                params.append('wall_panel_cutout[0][B4]', this.modelUploadForm.cuttingArr.deep);
                            } else {
                                params.append('wall_panel_cutout', '');
                            }
                            //关联体系
                            // for(var i in this.modelUploadForm.panelSystem){
                            //     params.append('relation_setup['+i+'][setup_id]',this.modelUploadForm.panelSystem[i]);
                            //     params.append('relation_setup['+i+'][material]','');
                            // }
                            //截面文件
                            if (this.modelUploadForm.sectionFile[0].raw) {
                                params.append('cad_section_file', this.modelUploadForm.sectionFile[0].raw);
                            } else {
                                params.append('cad_section_file', this.modelUploadForm.sectionFile[0].url);
                            }
                        }

                        if (this.structuralMemberShow) {
                            //判断为结构件
                            //是否需要暗光源
                            params.append('is_dark_light', this.modelUploadForm.lightSource);
                            //有无插槽
                            params.append('groove[type]', this.modelUploadForm.slot);
                            if (parseInt(this.modelUploadForm.slot) == 0) {
                                params.append('groove[medial_list]', '');
                                for (var d = 0; d < this.modelUploadForm.adaptation_board_width.length; d++) {
                                    params.append(
                                        'adaptation_board_width[' + d + ']',
                                        this.modelUploadForm.adaptation_board_width[d].value
                                    );
                                }
                            } else {
                                for (var j in this.modelUploadForm.slotArr) {
                                    params.append(
                                        'groove[medial_list][' + j + '][A1]',
                                        this.modelUploadForm.slotArr[j].nwidth
                                    );
                                    params.append(
                                        'groove[medial_list][' + j + '][A2]',
                                        this.modelUploadForm.slotArr[j].wwidth
                                    );
                                    params.append(
                                        'groove[medial_list][' + j + '][A3]',
                                        this.modelUploadForm.slotArr[j].width
                                    );
                                    params.append(
                                        'groove[medial_list][' + j + '][A4]',
                                        this.modelUploadForm.slotArr[j].deep
                                    );
                                }
                            }
                            //颜色
                            for (var z in this.modelUploadForm.color) {
                                params.append('color[' + z + ']', this.modelUploadForm.color[z]);
                            }
                            //关联体系
                            // let tmp = [];
                            // let struct = this.modelUploadForm.structSystem;
                            // for(var a=0;a<struct.length;a++){
                            //     if (tmp[struct[a][0]]){
                            //         tmp[struct[a][0]].push(struct[a][1])
                            //     }else{
                            //         tmp[struct[a][0]] = [struct[a][1]]
                            //     }
                            // }
                            // let index = 0;
                            // let index1 = 0;
                            // for (var b in tmp){
                            //     params.append('relation_setup['+index+'][setup_id]',b);
                            //     index1 = 0;
                            //     for (var c in tmp[b]){
                            //         params.append('relation_setup['+index+'][material]['+index1+']',tmp[b][c]);
                            //         index1++;
                            //     }
                            //     index++;
                            // }
                            //截面文件
                            if (this.modelUploadForm.sectionFile[0].raw) {
                                params.append('cad_section_file', this.modelUploadForm.sectionFile[0].raw);
                            } else {
                                params.append('cad_section_file', this.modelUploadForm.sectionFile[0].url);
                            }
                            //节点文件
                            if (this.modelUploadForm.nodeFile[0].raw) {
                                params.append('cad_node_file', this.modelUploadForm.nodeFile[0].raw);
                            } else {
                                params.append('cad_node_file', this.modelUploadForm.nodeFile[0].url);
                            }
                        }
                        //关联的贴图
                        if (this.modelUploadForm.reserveIdList.length) {
                            for (var model = 0; model < this.modelUploadForm.reserveIdList.length; model++) {
                                params.append(
                                    'relation_chartlet[' + model + ']',
                                    this.modelUploadForm.reserveIdList[model]
                                );
                            }
                        } else {
                            params.append('relation_chartlet', '');
                        }
                    } else {
                        if (this.modelUploadForm.trimPanel) {
                            params.append('texture_pattern[0]', this.modelUploadForm.trimPanel);
                        } else {
                            params.append('texture_pattern', '');
                        }
                    }
                    if (_this.panelShow) {
                        for (var f = 0; f < this.modelUploadForm.wall_panel_size.length; f++) {
                            params.append('wall_panel_size[' + f + ']', this.modelUploadForm.wall_panel_size[f].value);
                        }
                    }
                    if (_this.wareShow) {
                        for (var g = 0; g < this.modelUploadForm.idenViews.length; g++) {
                            if (_this.modelUploadForm.idenViews[0].raw) {
                                params.append('iden_view', this.modelUploadForm.idenViews[0].raw);
                            }
                        }
                    }
                    for (var h = 0; h < this.modelUploadForm.threeViews.length; h++) {
                        if (_this.modelUploadForm.threeViews[0].raw) {
                            params.append('three_view', this.modelUploadForm.threeViews[0].raw);
                        }
                    }
                    for (var key of params.entries()) {
                        // console.log(key[0] + ' => ' + key[1]);
                    }
                    if (this.modelId) {
                        //編輯
                        if (this.deleteImgArr.length) {
                            for (var e = 0; e < this.deleteImgArr.length; e++) {
                                params.append('del_file[' + e + ']', this.deleteImgArr[e]);
                            }
                        }
                        params.append('list_type', _this.listType);
                        modelEditAction(params).then(res => {
                            let { code, msg, result } = res;
                            if (code === 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${msg}`,
                                    center: true
                                });
                            }
                            _this.diaUrl = result.url;
                            this.dialogModelEditVisible = true;
                        });
                    } else {
                        //添加
                        uploadAddModelAction(params)
                            .then(res => {
                                let { code, msg, result } = res;
                                if (code === 200) {
                                    this.$message({
                                        type: 'success',
                                        message: `${msg}`,
                                        center: true
                                    });
                                    // this.grassrootsList = [];
                                    // this.keelList = [];
                                    // this.structureList = [];
                                    this.arr = [];
                                    this.trimPanelShow = false;
                                    this.structuralMemberShow = false;
                                    this.modelUploadForm = {
                                        // 体系
                                        system: '',
                                        relationSystem: '',
                                        // 分类
                                        classification: '',
                                        // 材料
                                        material: '',
                                        // 品牌id
                                        brandId: '',
                                        relationBrandId: '',
                                        // 类型
                                        type: '',
                                        // 模型名称
                                        modelName: '',
                                        // 上传文件
                                        file: [],
                                        cutting: 1,
                                        cuttingArr: {
                                            nwidth: '',
                                            wwidth: '',
                                            width: '',
                                            deep: ''
                                        },
                                        // panelSystem:[],
                                        sectionFile: [],
                                        lightSource: 1,
                                        slot: 2,
                                        slotArr: [],
                                        color: [],
                                        // structSystem:[],
                                        adaptation_board_width: [{ value: '' }],
                                        wall_panel_size: [{ value: '' }],
                                        format: {
                                            status: 0,
                                            // showSelect:false,
                                            arr: [
                                                {
                                                    id: '',
                                                    select1: true,
                                                    select2: true,
                                                    select3: true,
                                                    grassroots_id: [],
                                                    keel_id: [],
                                                    structure_id: [],
                                                    show: false,
                                                    length: '',
                                                    width: '',
                                                    height: ''
                                                }
                                            ]
                                        },
                                        scopes: [
                                            {
                                                minLength: '',
                                                minWidth: '',
                                                minHeight: '',
                                                maxLength: '',
                                                maxWidth: '',
                                                maxHeight: ''
                                            }
                                        ],
                                        trimPanel: ''
                                    };
                                    this.classificationList = [];
                                    this.materialList = [];
                                    _this.diaUrl = result.url;
                                    this.dialogModelEditVisible = true;
                                    this.$refs.modelUploadForm.resetFields();
                                }
                            })
                            .catch(err => {
                                // console.log(err);
                            });
                    }
                } else {
                    return false;
                }
            });
        }
    }
};
</script>
<style>
.ModelUploadCom .demo-input-label {
    display: inline-block;
    width: 130px;
}

.ModelUploadCom {
    color: #7f7f7f;
}

.ModelUploadCom::before {
    content: '';
    display: table;
}

.ModelUploadCom .title {
    width: 100%;
    text-align: center;
    height: 65px;
    line-height: 65px;
    border-bottom: 1px solid #dcdfe6;
    font-weight: 700;
    font-size: 18px;
    color: rgb(127, 127, 127);
}

.ModelUploadCom .upload-content-wrap {
    width: 80%;
    margin: 30px auto 50px;
}

.ModelUploadCom .upload-content-wrap .upload-content-tips :deep(.el-form-item__content) {
    margin-left: 15px !important;
}

.ModelUploadCom .upload-content-wrap .upload-content-tips span:first-child {
    display: inline-block;
    width: 32px;
    height: 32px;
    background-color: #0099ff;
    font-size: 26px;
    color: #ffffff;
    text-align: center;
    line-height: 32px;
    border-radius: 50%;
}

.ModelUploadCom .upload-content-wrap .upload-content-tips span:last-child {
    position: relative;
    top: -5px;
    left: 5px;
}

.ModelUploadCom .upload-file-wrap .upload-file :deep(.el-upload .el-upload-dragger) {
    position: relative;
    min-width: 890px;
    line-height: 30px;
}

.ModelUploadCom .specsInput1 .el-input__icon:after {
    content: '宽' !important;
    width: 15px;
}

.ModelUploadCom .specsInput2 .el-input__icon:after {
    content: '深' !important;
    width: 15px;
}

.ModelUploadCom .specsInput3 .el-input__icon:after {
    content: '厚' !important;
    width: 15px;
}

.ModelUploadCom .specsInput4 .el-input__icon:after {
    content: '长' !important;
    width: 15px;
}

.ModelUploadCom .specsInput .el-icon-search:before {
    display: none;
}

.ModelUploadCom .specsInput .el-input__suffix-inner .el-input__icon:after {
    content: 'mm ' !important;
    width: 26px;
    padding-right: 8px;
    height: 35px;
    line-height: 30px;
}

.ModelUploadCom .second-label .el-form-item__label {
    padding: 0;
}

.ModelUploadCom .second-label .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
    display: none;
}

.ModelUploadCom .addIcon {
    display: inline-block;
    cursor: pointer;
    width: 30px;
    height: 30px;
    text-align: center;
    margin-left: 8px;
    line-height: 30px;
    border: 1px solid #dcdfe6;
    color: var(--el-text-color-regular);
    background-color: #ffffff;
    margin-top: 1px;
}

.ModelUploadCom .el-checkbox.is-bordered {
    padding: 7px 12px;
    height: 35px;
}

.ModelUploadCom .el-radio.is-bordered {
    padding: 9px 12px;
    height: 35px;
}

.ModelUploadCom .el-button+.el-button,
.el-checkbox.is-bordered+.el-checkbox.is-bordered,
.el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 0 !important;
}

.ModelUploadCom .el-checkbox {
    margin-right: 5px;
}

/*.ModelUploadCom .bImage .el-image-viewer__wrapper{*/
/*    z-index:99999!important;*/
/*}*/
</style>