import $http from '../../../../utils/http.js';

/**
 * 查询体系/分类/材料/品牌数据
 * @param data
 */
export function getAttributesData(data) {
    return $http.get('/attributes_list', data, 'loadingDiv');
}
export function getAttributesGetModel(data) {
    return $http.get('/get_model_class', data, 'loadingDiv');
}

/**
 * 上传模型
 * @param data
 */
export function uploadAddModelAction(data) {
    return $http.post('/add_model', data, 'loadingDiv', 'multipart/form-data');
}

/**
 * 模型名称查重
 * @param data
 */
export function modelNameRecheckAction(data) {
    return $http.get('/query_modelName', data, 'loadingDiv');
}

/**
 * 查詢关联贴图
 * @param data
 * @returns {Promise<unknown>}
 */
export function obtainRelation(data) {
    return $http.get('/obtain_relation',data,'aa');
}

/**
 * 查詢模型詳情
 * @param data
 * @returns {Promise<unknown>}
 */
export function getModelDetail(data) {
    return $http.get('/model_details',data,'loadingDiv');
}