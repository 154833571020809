import $http from '../../../../utils/http.js';

/**
 * 查询模型列表/待审核/问题模型数据
 * @param data
 */
export function getModelLibraryData(data) {
    return $http.get('/models_list', data, 'loadingDiv');
}

/**
 * 模型启用/禁用操作
 * @param data
 */
export function modelChangeStatusAction(data) {
    return $http.post('/change_status', data, 'loadingDiv');
}

/**
 * 编辑模型
 * @param data
 */
export function modelEditAction(data) {
    return $http.post('/change_model', data, 'loadingDiv','multipart/form-data');
}

/**
 * 生成版本配置
 * @param data
 */
export function modelGenerateConfig(data) {
    return $http.exportsTxt('/newGenerate_deploy', data, 'loadingDiv', '版本配置.txt');
}
