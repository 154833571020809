<template>
    <div v-if="dialogVisible" class="edit-model-model">
        <el-dialog
                v-model="dialogVisible1"
                title="编辑模型"
                width="100%"
                top="0px"
                center
                :before-close="handleClose">
            <div style="width: 100%;height: calc(100vh - 50px)">
                <iframe ref="iframe" :src="url ? url : canvasUrl" height="100%" width="100%"></iframe>
            </div>

        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: 'editModel',
        props: {
            dialogVisible: {
                type: Boolean,
                default: false
            },
            url:{
                type:String,
                default:null
            }

        },
        data() {
            return {
                dialogVisible1:true,
            };
        },
        mounted () {
            window.addEventListener('message', this.handleMessage)
            this.$nextTick(() => {
                let _this = this;
                this.$refs.iframe.onload = function(){
                    _this.$refs.iframe.contentWindow.postMessage({
                        cmd: 'getFormJson',
                        params: {id:10010}
                    }, '*')
                };
            })
        },
        methods: {
            handleClose(){
                window.removeEventListener('message',function () {
                    // alert('删除时间')
                });
                this.$emit('closeDialog',false);
            },
            handleMessage (event) {
                const data = event.data
                switch (data.cmd) {
                    case 'returnFormJson':
                        // alert("Test："+data.params.success);
                        // 业务逻辑
                        break
                    case 'returnHeight':
                        // alert("vue接收："+data.params.data);
                        // 业务逻辑
                        break
                }
            }
        }
    };
</script>

<style>
    .edit-model-model .el-overlay{
        z-index: 99999999999!important;
    }

</style>